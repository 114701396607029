import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {

  constructor() { }
}

// export const FRONTEND_URL = 'http://localhost:4200';
// export const API_BASE_URL = 'http://localhost:8080';

export const FRONTEND_URL = 'https://gdic-demo.bdlancelimited.com';
export const API_BASE_URL = 'https://gdic-demo.bdlancelimited.com';
