import { Injectable } from '@angular/core';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const USER_BRANCH_KEY = 'auth-user-branch';
const INTIMATION_FORM = 'form-intimation';
const SURVEY_FORM = 'form-survey';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor() { }

  signOut(): void {
    window.localStorage.clear();
  }

  public saveToken(token: string): void {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return window.localStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: any): void {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
    window.localStorage.setItem(USER_BRANCH_KEY, "");
  }

  public getUser(): any {
    const user = window.localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return {};
  }

  public saveBranch(branchId: any): void {
    window.localStorage.removeItem(USER_BRANCH_KEY);
    window.localStorage.setItem(USER_BRANCH_KEY, branchId);
  }

  public getBranch(): any {
    const userBranch = window.localStorage.getItem(USER_BRANCH_KEY);
    if (userBranch) {
      return userBranch;
    }

    return "";
  }

  public saveIntimationForm(data: any): void {
    window.localStorage.removeItem(INTIMATION_FORM);
    window.localStorage.setItem(INTIMATION_FORM, JSON.stringify(data));
  }
  
  public getIntimationForm(): any {
    const formData = window.localStorage.getItem(INTIMATION_FORM);
    if(formData) {
      return JSON.parse(formData);
    }

    return null;
  }

  public saveSurveyForm(data: any): void {
    window.localStorage.removeItem(SURVEY_FORM);
    window.localStorage.setItem(SURVEY_FORM, JSON.stringify(data));
  }
  
  public getSurveyForm(): any {
    const formData = window.localStorage.getItem(SURVEY_FORM);
    if(formData) {
      return JSON.parse(formData);
    }

    return null;
  }


}
