import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_BASE_URL } from '../../cargo-import-claim/_services/claims.service';

import { TokenStorageService } from './token-storage.service';

const AUTH_API = `${API_BASE_URL}/api/auth/`;

const httpOptions = {
	headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  
@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(private http: HttpClient, private tokenStorage: TokenStorageService, private router: Router) { 
		if(!this.isUserLoggedIn()) {
			this.router.navigate(['/auth']);
		}
	}

	login(username: string, password: string): Observable<any> {
		const formData = new FormData();
		formData.append("username", username);
		formData.append("password", password);
	
		return this.http.post(AUTH_API + 'signin', formData);
	}
  
	// register(username: string, email: string, password: string): Observable<any> {
	//   return this.http.post(AUTH_API + 'signup', {
	// 	username,
	// 	email,
	// 	password
	//   }, httpOptions);
	// }

	public isUserLoggedIn() {
		if(this.tokenStorage.getToken())
			return true;
		else
			return false;
	}

	public getUser() {
		return this.tokenStorage.getUser();
	}

}
